@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';

.myClassname,
.react-datepicker {
    font-size: 1em;
}

.myClassname,
.react-datepicker__current-month {
    font-size: 1em;
}

.myClassname,
.react-datepicker__navigation--previous {
    top:9px
}

.myClassname,
.react-datepicker__navigation--next {
    top:9px
}

.myClassname,
.react-datepicker__header__dropdown {
    padding: 3px;
}

.myClassname,
.react-datepicker__navigation-icon::before {
    border-color: black;
}

.myClassname,
.react-datepicker__day--keyboard-selected {
    background-color: #99CAEA
}

.myClassname,
.react-datepicker__day--keyboard-selected:hover {
    background-color: #99CAEA
}

.myClassname,
.react-datepicker__time-list-item .react-datepicker__time-list-item--selected {
    background-color: #003874
}

.myClassname,
.react-datepicker__day--selected {
    background-color: #003874
}

/* Styles for On Hover of selected */
.myClassname,
.react-datepicker__day--selected:hover {
    background-color: #003874
}

.myClassname,
.react-datepicker__header {
    background-color: #fafafa;
}

.myClassname,
.react-datepicker__day-names {
    font-weight: 700;
}

.myClassname,
.react-datepicker__day--today {
    border-radius: 0.3rem;
    background: #B49759;
}

.myClassname,
.react-datepicker__day--today:hover {
    border-radius: 0.3rem;
    background: #B49759;
}

.myClassname,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    height: 1.8em;
    width: 2em;
}

.myClassname,
.react-datepicker-time__caption {
    font-weight: bold;
}

.myClassname,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #003874
}

.myClassname,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #003874
}

.myClassname,
.react-datepicker__close-icon {
    top: 32%;
    right: 3rem;
    width: 18px;
    height: 18px;
    position: absolute;
    padding: 0;
}

.myClassname,
.react-datepicker__close-icon::after {
    right: 0 !important;
    top: 0;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background-color: #003874;
}

.myClassname,
.react-datepicker-wrapper {
    height: 34px;
}