.dynamic-content-div figure {
  width: 100%!important;
}

.dynamic-content-div img {
  display: block;
  width: 100%!important;
  object-fit: contain;
}

@media (max-width: 767px) {
  .dynamic-content-div img {
    height: auto!important;
  }
}

@media (max-width: 391px) {
  .signOut {
    padding-top: 0.5rem;
  }
}