.vaContainerWithoutOverlay {
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  .vaImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom: 1px solid lightgray;
  }
}

.vaContainer {
  position: relative;
  width: 100%;
  cursor: pointer;
  padding-top: 56.25%;

  .vaImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom: 1px solid lightgray;
  }

  .vaOverlay {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: transparent;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  }

  .vaText {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.vaContainer:hover .vaImage {
  opacity: 0.3;
}

.vaContainer:hover .vaOverlay {
  bottom: 0;
  height: 100%;
}

