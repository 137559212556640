.textBlock {
  height: 5em;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25em;
}

.cfLineHeight {
  line-height: 1.2em;
}

.cursorPointer {
  cursor: pointer
}

.textAreaBlock {
  height: 2.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  white-space: pre-line;
}

.buttonWrapper {
  .clipboardButton {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 0px 0px;
    text-align: center;
    font-size: 14px;
  }
}
