@import "src/styles/kiwanis-colors.scss";

.contestEnded {
  font-weight: bold;
  font-size: x-large;
  color: #003874;
}

.countdownWrapper {
  display: inline-flex;
}

.countdownBox {
  background-color: $kiwanis-blue-color;
  font-size: 30px;
  font-weight: 700;
  color: $kiwanis-light-blue-color;
  border-radius: 15px;
  margin-right: 10px;
  min-width: 80px;
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.legend {
  font-size: 15px;
  color: $kiwanis-light-blue-color;
}

@media (max-width: 767px) {
  .countdownBox {
    background-color: $kiwanis-blue-color;
    font-size: 20px;
    font-weight: 700;
    color: $kiwanis-light-blue-color;
    border-radius: 15px;
    margin-right: 10px;
    min-width: 65px;
    width: 65px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .legend {
    font-size: 13px;
    color: $kiwanis-light-blue-color;
  }
}