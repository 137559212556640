.kiwanis-voting-app {
  @import "styles/kiwanis-colors";

  $primary: $kiwanis-blue-color;
  $primary-rgb: $kiwanis-blue-color;
  $nav-link-color: $white-color;
  $nav-link-hover-color: $kiwanis-gray-color;

  $font-size: 1em;
  $btn-font-size: 1em;
  $input-font-size: 1em;
  $form-select-font-size: 1em;

  @import "~bootstrap/scss/bootstrap";

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-bg: #{$body-bg};

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }


  /************************************************
  *	Headings
  ************************************************/

  h1, h2, h3, h4, h5, h6 {
    color: #333333;
    color: rgba(0, 0, 0, 0.8);
    font-family: Arial-Bold, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.4;
    word-wrap: break-word;
    margin: 4px 0;
    padding: 4px 0;
  }

  h1 {
    font-size: 2.6em;
    letter-spacing: -1px;
  }

  h2 {
    font-size: 2.4em;
    letter-spacing: -1px;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.1em;
  }

  h1.headline {
    font-family: sans-serif;
    font-size: 3em;
  }

  .headline {
    font-size: 3em;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: -1px;
    word-wrap: break-word;
    margin: 0;
    padding: 0 0 12px 0;
  }

  a.nav-link.active span.navItemLink {
    border-bottom: $white-color 4px solid;
    max-width: max-content;
  }

  .form-label {
    font-weight: bold;
  }

  .required-asterisk {
    color: $kiwanis-gold-color;
    font-size: 1.1em;
    margin-left: 3px;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  /************************************************
   *	Entry list page							*
   ************************************************/

  .notActiveButton {
    background-color: transparent;
    border: none;
    color: black;
    cursor: auto !important;
    box-shadow: none !important;
    outline: none;
  }

  .notActiveButton:active {
    background-color: transparent;
    border: none;
    color: black;
    cursor: auto;
  }

  .notActiveButton:hover {
    background-color: transparent;
    border: none;
    color: black;
    cursor: auto;
  }

  .notActiveButton:focus {
    background-color: transparent;
    border: none;
    color: black;
    cursor: auto;
  }

  .notActiveButton:focus-visible {
    background-color: transparent;
    border: none;
    color: black;
    cursor: auto;
    outline: none;
  }

  .edVoteButton {
    background-color: white;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .edVoteButton:active {
    background-color: white;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .edVoteButton:focus {
    background-color: white;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .edVoteButton:hover {
    background-color: white;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .edVoteButton span svg {
    color: $kiwanis-blue-color;
  }

  .elVoteButton {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .elVoteButton:active {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .elVoteButton:focus {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .elVoteButton:hover {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
    transition: background-color 0.5s;
  }

  .elVoteButton > span > svg {
    color: $kiwanis-blue-color;
  }

  .ReactModal__Content:focus {
    outline: none;
  }

  .ReactModal__Content:focus-visible {
    outline: none;
  }

  /************************************************
   *	Mobile Devices							*
   ************************************************/

  @media only screen and (max-width: 419px) {
    div h1.headline {
      font-size: 2em;
    }
  }
}